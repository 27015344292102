export function toCamelCase(name: string) {
  return name.replace(/-(\w)/g, match => match[1].toUpperCase());
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function buildQueryString(params: object): string {
  if (!params) {
    return '';
  }
  return Object.keys(params)
    .map((key) => {
      let s = encodeURIComponent(key);
      const value = params[key];
      if (value !== null && typeof value !== 'undefined') {
        s += `=${encodeURIComponent(value)}`;
      }
      return s;
    })
    .join('&');
}


export function unEscapeHtml(input: string) {
  return String(input)
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, '\'')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
}


