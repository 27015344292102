import * as React from "react";
const cookies = require("browser-cookies");
import "./styles/GDPRpopup.scss";
import Button from "./Button";
import Switch from "./Switch";
import Scrollbar from "./Scrollbar";
import { translate } from "../../l10n";

const t = (str: string, context = "common") => translate(context, str);

export interface GDPRpopupProps {
  onCloseModal: () => () => void;
}

interface State {
  settingsView: boolean;
  cookie: {
    statistic: boolean;
    marketing: boolean;
  };
}

class GDPRpopup extends React.Component<GDPRpopupProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      settingsView: false,
      cookie: {
        statistic: true,
        marketing: true,
      },
    };
    this.toggleSettings = this.toggleSettings.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(cookieType) {
    return (on) => {
      this.setState(({ cookie }) => ({
        cookie: {
          ...cookie,
          [cookieType]: on,
        },
      }));
    };
  }

  toggleSettings() {
    this.setState(({ settingsView }) => ({ settingsView: !settingsView }));
  }

  onAccept() {
    const {
      cookie: { statistic },
    } = this.state;
    cookies.set(
      "cookie_consent_bit",
      [true, statistic].map((v) => (v ? 1 : 0)).join(""),
      { expires: 365 }
    );
    // trigger a custom event in Google tag manager
    if (window.dataLayer) {
      window.dataLayer.push({ event: "cookie_consent_bit_given" });
    }
    this.props.onCloseModal();
  }

  renderSettings() {
    //eslint-disable-line
    const {
      cookie: { statistic },
    } = this.state;
    return (
      <section className="mt-10">
        <article>
          <h3>{t("GDPRpopup.gdprHeader")}</h3>
          <p>
            {t("GDPRpopup.gdprParagraph1")}
            <br />
            {t("GDPRpopup.gdprParagraph2")}
          </p>
          <ul>
            <li>
              <strong>{t("GDPRpopup.googleRecaptcha")}</strong>
            </li>
          </ul>
          <Switch checked disabled onChange={() => {}} />
        </article>
        <article>
          <h3>{t("GDPRpopup.gdprHeader2")}</h3>
          <p>
            {t("GDPRpopup.gdprParagraph3")} <br />
            {t("GDPRpopup.gdprParagraph4")}
          </p>
          <ul>
            <li>
              <strong>{t("GDPRpopup.googleAnalytics")}</strong>
            </li>
            <li>
              <strong>{t("GDPRpopup.googleAdWords")}</strong>
            </li>
          </ul>
          <Switch checked={statistic} onChange={this.onChange("statistic")} />
        </article>
      </section>
    );
  }

  renderContent() {
    //eslint-disable-line
    return (
      <section>
        <p>{t("GDPRpopup.gdprParagraph5")}</p>
        <p>{t("GDPRpopup.gdprParagraph6")}</p>
        <p>{t("GDPRpopup.gdprParagraph7")}</p>
        <p>{t("GDPRpopup.gdprParagraph8")}</p>
        <p>{t("GDPRpopup.gdprParagraph9")}</p>
      </section>
    );
  }

  renderFooter() {
    const { settingsView } = this.state;
    return (
      <footer>
        <div className="dialog-custom-footer">
          {settingsView ? (
            <Button type="black" onClick={this.toggleSettings}>
              {t("button.previous", "common")}
            </Button>
          ) : (
            <Button type="black" onClick={this.toggleSettings}>
              {t("button.settings", "common")}
            </Button>
          )}
          <Button type="primary" onClick={this.onAccept}>
            {t("button.accept", "common")}
          </Button>
        </div>
      </footer>
    );
  }

  render() {
    const { settingsView } = this.state;
    return (
      <div>
        <div className="dialog-custom-content gdpr-popup">
          <Scrollbar
            style={{
              width: "100%",
              height: "400px",
            }}
          >
            {settingsView ? this.renderSettings() : this.renderContent()}
          </Scrollbar>
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default GDPRpopup;
