import keyMirror from "key-mirror";
import { createStandardAction } from "typesafe-actions";

export const actionTypes = keyMirror({

  ACCESS_SHARED_CONSOLE: null,
  ACCESS_SHARED_CONSOLE_ERROR: null,
  ACCESS_SHARED_CONSOLE_SUCCESS: null,


  REQUEST_RESTART: null,

  SEND_REQUEST_RESTART: null,
  SEND_REQUEST_RESTART_ERROR: null,
  SEND_REQUEST_RESTART_SUCCESS: null,

});

const at = actionTypes;

export type RequestPayload = { consoleShareId: string, password: string }

export const actionCreators = {
  accessSharedConsole: createStandardAction(at.ACCESS_SHARED_CONSOLE).map(
    (payload: RequestPayload) => ({
      payload,
      meta: {
        request: {
          url: '/SharedConsole/AccessSharedConsole',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),
  requestRestartConsole: createStandardAction(at.SEND_REQUEST_RESTART).map(
    (payload: RequestPayload) => ({
      payload,
      meta: {
        request: {
          url: '/SharedConsole/RequestRestart',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),
  requestRestart: createStandardAction(at.REQUEST_RESTART)<RequestPayload>(),
}



