import { actionTypes as at, actionCreators } from '../actions';
import { ActionType } from 'typesafe-actions';
import Types from 'Types';

export type Action = ActionType<typeof actionCreators>;

export const initialState = {
    data: null,
    loading: false,
};

export const reducer = (state = initialState, { type, payload }: Action & Types.StoreAction) => {
    switch (type) {
        case at.EMAIL_MARKETING:
            return {
                data: null,
                loading: true,
            }
        case at.EMAIL_MARKETING_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case at.EMAIL_MARKETING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
            }
        default:
            return state;
    }
};

export const isLoading = ({ submitForm: { emailMarketing } }: Types.StoreState) => emailMarketing.loading;

export const getData = ({ submitForm: { emailMarketing } }: Types.StoreState) => emailMarketing.data;

