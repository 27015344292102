import { takeLatest, put, select } from "redux-saga/effects"
import { actionTypes as at } from "./actions";
import { showModal } from "../../shared/sagas/modals/actions";
import { getResetCodeData } from './reducer';
import { translate } from '../../l10n';

const t = (str, context = "guest") => translate(context, str);

export function* handleComponentResetCodeSuccess() {
  const resetVerificationCode = yield select(getResetCodeData);
  if (resetVerificationCode) {
    const { status } = resetVerificationCode;
    if (status === "ok") {
      yield put(
        showModal({
          type: "alert",
          alertType: "success",
          title: t("confirmRegistration.title"),
          message: t("confirmRegistration.resetCodeSuccess"),
          onClose: () => {
            window.location.assign("/");
          },
        })
      );
    }
    else {
      yield put(
        showModal({
          type: "alert",
          alertType: "error",
          title: t("confirmRegistration.title"),
          message: t("confirmRegistration.resetCodeError"),
          onClose: () => {
            window.location.assign("/");
          },
        })
      );
    }
  }


}

export const sagas = function* () {
  yield takeLatest(at.EMAIL_CONFIRMATION_RESET_CODE_SUCCESS, handleComponentResetCodeSuccess);
};