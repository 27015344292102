/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
/* eslint-disable no-useless-escape */

function comparePasswords(nPassword, cPassword) {
  if (nPassword.trim().length < 2) {
    return false;
  }
  const newPassword = nPassword.toLowerCase();
  const currentPassword = cPassword.toLowerCase();

  if (newPassword === currentPassword) {
    return false;
  }

  const tokens = currentPassword.split(/[\~\!\@\#\$\%\^\&\*<>\+\-=]/);
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].length > 3) {
      const subTokens = tokens[i];
      for (let j = 0; j < subTokens.length - 2; j++) {
        const subToken = subTokens.substring(j, j + 3);
        if (newPassword.indexOf(subToken) > -1) {
          return false;
        }
      }
    }
    if (tokens[i].length > 2 && newPassword.indexOf(tokens[i]) > -1) {
      return false;
    }
  }
  return true;
}

export function checkStringNotInAnotherString(password, propertyText) {
  if (password.length === 0) {
    return false;
  }
  if (propertyText === undefined) {
    return true;
  }
  if (password === propertyText) {
    return true;
  }

  const tokens = propertyText.split(/[\s,\._@-]/);
  // - 1 in order to avoid checking for top level domain
  for (let i = 0; i < tokens.length - 1 || i === 0; i++) {
    if (tokens[i].length > 2 && password.toLowerCase().indexOf(tokens[i].toLowerCase()) > -1) {
      return true;
    }
  }
  return false;
}

export function doesNotContainString(password, firstName, lastName) {
  if (password === firstName || password === lastName) {
    return true;
  }
  if (firstName && password.toLowerCase().indexOf(firstName.toLowerCase()) !== -1) {
    return true;
  }
  if (lastName && password.toLowerCase().indexOf(lastName.toLowerCase()) !== -1) {
    return true;
  }
  return false;
}

export function validatePasswordField(password, { email, currentPassword, firstName, lastName }) {
  const errors: { [x: string]: boolean } = {};

  // validate the length
  if ((/[\s]/).test(password)) {
    errors.length = true;
  }

  if (password.length > 20 || password.length < 8) {
    errors.length = true;
  }

  // validate contents
  if (checkStringNotInAnotherString(password, email)) {
    errors.email = true;
  }

  if (doesNotContainString(password, firstName, lastName)) {
    errors.doesNotContain = true;
  }

  // validate latin letter
  if (!(/^[a-zA-Z0-9~!@#\^\$&\*\(\)_\+=\[\]\{\}\|,\.\?<>'\":;`%|-]{1,}$/).test(password)) {
    errors.latin = true;
  }

  // validate capital letter
  if (!password.match(/[A-Z]/)) {
    errors.upperCase = true;
  }
  // validate small caps letter
  if (!password.match(/[a-z]/)) {
    errors.lowerCase = true;
  }

  // validate digit
  if (!password.match(/\d/)) {
    errors.number = true;
  }

  // validate special symbol
  if (!password.match(/[~!@#\^\$&\*\(\)_\+=\[\]\{\}\|,\.\?<>'\":;`%|-]/)) {
    errors.specialSymbol = true;
  }

  // validate (compare) current with new password
  if (currentPassword && currentPassword.trim().length) {
    if (!comparePasswords(password, currentPassword)) {
      errors.compare = true;
    }
  }
  return {
    errors,
    options: {
      showComparePassword: currentPassword !== undefined,
      showDoesNotContain: firstName !== undefined && lastName !== undefined,
    },
  };
}

export default { validatePasswordField };
