import { actionTypes as at, actionCreators } from '../actions';
import { ActionType } from 'typesafe-actions';
import Types from 'Types';


export type Action = ActionType<typeof actionCreators>;

export const initialState = {
  data: null,
  loading: false,
};

export const reducer = (state = initialState, { type, payload }: Action & Types.StoreAction) => {
  switch (type) {
      case at.RESET_PASSWORD:
        return {
          data: null,
          loading: true,
        }
      case at.RESET_PASSWORD_ERROR:
        return {
          ...state,
          loading: false,
          error: payload,
        }
      case at.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          data: payload,
        }
      default:
        return state;
  }
};

export const isLoading = ({ submitForm: { resetPassword } }: Types.StoreState) => resetPassword.loading;

export const getData = ({ submitForm: { resetPassword } }: Types.StoreState) => resetPassword.data;

