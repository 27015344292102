import * as React from 'react';
import Spinner from '../components/Spinner';

const loadedChunkNames = {};

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */


export interface AsyncComponentProps {
  path: string,
  showError: (error?: any) => void,
}

export interface AsyncComponentState {
  Component: React.FunctionComponent;
}

export default function (getComponent, onError?: (...args: any[]) => void) {
  let Component: any = null;
  class AsyncComponent extends React.Component<AsyncComponentProps, AsyncComponentState> {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time outside of the
     * normal render path.
     */
    static load() {
      return getComponent().then((module) => {
        Component = module.default || module;
      });
    }

    constructor(props) {
      super(props);

      this.state = {
        Component,
      };
      this.updateState = this.updateState.bind(this);
    }

    UNSAFE_componentWillMount() {
      AsyncComponent.load()
        .then(this.updateState)
        .catch((error) => {
          if (onError) {
            onError(error, this.props);
          } else if (this.props.showError) {
            this.props.showError(error);
          }
        });
    }

    updateState() {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      if (this.state.Component !== Component) {
        this.setState({
          Component,
        });
      }
    }

    render() {
      const { Component: LoadedComponent } = this.state;

      if (!LoadedComponent) {
        return (<div style={{ minHeight: window.innerHeight / 1.2 }} >
          <Spinner top={100} />
        </div>);
      }

      const chunkName = this.props.path;
      const className = loadedChunkNames[chunkName] ? '' : 'fade-in';
      loadedChunkNames[chunkName] = true;

      return (<div className={className}>
        <LoadedComponent {...this.props} />
      </div>);
    }
  }

  return AsyncComponent;
}
