import * as React from "react";
import classNames from "classnames";
import "./styles/PasswordTooltip.scss";
import { PasswordStrengthMeter } from "./PasswordStrengthMeter";
import { translate } from "../../../l10n";

const t = (str, context = "common") => translate(context, str);

export interface PasswordTooltipProps {
  errors: {
    length?: boolean;
    email?: boolean;
    latin?: boolean;
    lowerCase?: boolean;
    upperCase?: boolean;
    number?: boolean;
    specialSymbol?: boolean;
    doesNotContain?: boolean;
    compare?: boolean;
  };
  options: {
    showComparePassword?: boolean;
    showDoesNotContain?: boolean;
    skipEmailValidation?: boolean;
    showPasswordStrength?: boolean;
  };
}

const PasswordTooltip = ({ errors, options }: PasswordTooltipProps) => {
  const {
    length,
    email,
    latin,
    lowerCase,
    upperCase,
    number,
    specialSymbol,
    doesNotContain,
    compare,
  } = errors;
  const addClass = (invalid) =>
    classNames("password-tooltip__icon", {
      "icon-cross": invalid,
      "icon-checkmark": !invalid,
    });
  const allErrorLength = Object.keys({
    length,
    email,
    latin,
    lowerCase,
    upperCase,
    number,
    specialSymbol,
    doesNotContain,
    compare,
  }).length;
  let possibleErrorLenth = allErrorLength;
  if (!options.showComparePassword) {
    possibleErrorLenth--;
  }
  if (!options.showDoesNotContain) {
    possibleErrorLenth--;
  }
  if (!options.skipEmailValidation) {
    possibleErrorLenth--;
  }
  // console.log("compare " + compare);
  // console.log("lenght " + length);
  const diff = allErrorLength - possibleErrorLenth;
  return (
    <div className="password-tooltip">
      <ul>
        <li>
          <i className={addClass(length)} />
          <span>{t("passwordTooltip.length")}</span>
        </li>
        {options.skipEmailValidation && (
          <li>
            <i className={addClass(email)} />
            <span>{t("passwordTooltip.email")}</span>
          </li>
        )}
        <li>
          <i className={addClass(latin)} />
          <span>{t("passwordTooltip.character")}</span>
        </li>
        <li>
          <i className={addClass(upperCase)} />
          <span>{t("passwordTooltip.upperCharacter")}</span>
        </li>
        <li>
          <i className={addClass(lowerCase)} />
          <span>{t("passwordTooltip.lowerCharacter")}</span>
        </li>
        <li>
          <i className={addClass(number)} />
          <span>{t("passwordTooltip.digit")}</span>
        </li>
        {options.showComparePassword && (
          <li>
            <i className={addClass(compare)} />
            <span>{t("passwordTooltip.password")}</span>
          </li>
        )}
        {options.showDoesNotContain && (
          <li>
            <i className={addClass(doesNotContain)} />
            <span>{t("passwordTooltip.nameOrFamilyname")}</span>
          </li>
        )}
        <li>
          <i className={addClass(specialSymbol)} />
          <span>{t("passwordTooltip.specialCharacter")}</span>
          <br />
          <span>{t("passwordTooltip.specialCharacterExample")}</span>
        </li>
      </ul>
      {options.showPasswordStrength && (
        <PasswordStrengthMeter
          strength={
            possibleErrorLenth -
            Object["values"](errors).filter((hasError) => hasError).length -
            diff
          }
        />
      )}
    </div>
  );
};

export default PasswordTooltip;
