import keyMirror from 'key-mirror';
import { createStandardAction } from "typesafe-actions";
import { toFormData } from "../../shared/tools/dataTools";

export const actionTypes = keyMirror({

  REGISTRATION: null,
  REGISTRATION_ERROR: null,
  REGISTRATION_SUCCESS: null,

  CONTACT_US: null,
  CONTACT_US_ERROR: null,
  CONTACT_US_SUCCESS: null,

  EMAIL_MARKETING: null,
  EMAIL_MARKETING_ERROR: null,
  EMAIL_MARKETING_SUCCESS: null,

  RESET_PASSWORD: null,
  RESET_PASSWORD_ERROR: null,
  RESET_PASSWORD_SUCCESS: null,

  FORGOTTEN_PASSWORD: null,
  FORGOTTEN_PASSWORD_ERROR: null,
  FORGOTTEN_PASSWORD_SUCCESS: null,

  LEADS_MARKETING: null,
  LEADS_MARKETING_ERROR: null,
  LEADS_MARKETING_SUCCESS: null,

  LOGIN: null,
  LOGIN_ERROR: null,
  LOGIN_SUCCESS: null,

  CHECK_RESET_PASSWORD_CODE: null,
  CHECK_RESET_PASSWORD_CODE_ERROR: null,
  CHECK_RESET_PASSWORD_CODE_SUCCESS: null,
});

const at = actionTypes;

export const actionCreators = {
  registration: createStandardAction(at.REGISTRATION).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/V2Registration',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken",
        },
      }
    })
  ),
  contactUs: createStandardAction(at.CONTACT_US).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/V2ContactUs',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken",
        },
      }
    })
  ),
  emailMarketing: createStandardAction(at.EMAIL_MARKETING).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Marketing/Subscribe',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken",
        },
      }
    })
  ),
  leadsMarketing: createStandardAction(at.LEADS_MARKETING).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
          method: 'POST',
          body: toFormData(payload),
        },
      }
    })
  ),
  resetPassword: createStandardAction(at.RESET_PASSWORD).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/V2ChangePassword',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken",
        },
      }
    })
  ),
  checkResetPasswordCode: createStandardAction(at.CHECK_RESET_PASSWORD_CODE).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/CheckResetPasswordCode',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),
  forgottenPassword: createStandardAction(at.FORGOTTEN_PASSWORD).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/V2PasswordRecover',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken"
        },
        //suppressError: true,
      }
    })
  ),
  login: createStandardAction(at.LOGIN).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Home/Login',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken"
        },
      }
    })
  ),
}
