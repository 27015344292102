import * as React from 'react';
import LazyLoad from '../../../shared/components/LazyLoad';
import FullscreenSlider from './FullscreenSlider';
import WhyOblak from './WhyOblak';
import CloudCalculator from './CloudCalculator';

function IndexGuest(props) {
    return (<React.Fragment>
        <style>
            {
                // TODO move it in css files
                `.page {
                    background: transparent;
                }`
            }
        </style>
        <FullscreenSlider {...props} />
        <LazyLoad height={800} offset={200} once >
            <CloudCalculator {...props} />
        </LazyLoad>
        <LazyLoad height={1100} offset={100} once >
            <WhyOblak />
        </LazyLoad>
    </React.Fragment>);
}

export default IndexGuest;
