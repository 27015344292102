import { delay } from "redux-saga";
import { call, takeLatest, put } from "redux-saga/effects";
import { actionTypes as at, actionCreators as ac } from "./actions";
//import * as SmartBanner from 'smart-app-banner';
import * as cookies from 'browser-cookies';

/*const showSmartBanner = function (force: string) {
  const n = document.querySelector('.smartbanner');
  if (n) {
    n.parentNode.removeChild(n);
  }
  new SmartBanner({
    daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
    title: 'Oblak.bg',
    author: 'Haemimont AD',
    button: 'VIEW',
    force,
    show() {
      (document.querySelector('.header') as HTMLElement)
        .style.marginTop = '80px';
    },
    close() {
      (document.querySelector('.header') as HTMLElement)
        .style.marginTop = '';
    },
  });
};*/


export function* handleDocumentReady() {
  /*if (/(android)/i.test(window.navigator.userAgent)) {
    showSmartBanner('android');
  }*/

  if (!cookies.get('cookie_consent_given')) {
    yield put(ac.showCookieConsent());
  }

  if (!cookies.get('cookie_consent_bit')) {
    yield call(delay, 500);
    yield put(ac.showPersonalDataPopup());
  }
}

export const sagas = function* () {
  yield takeLatest(at.DOCUMENT_READY, handleDocumentReady);
};