import { actionTypes as at } from './actions';

export const initialState = {
  details: {
    AccountId: 0,
    ConsoleShareId: null,
    ExpirationDate: null,
    InstanceId: null,
    VmOs: null,
    VmState: null,
    password: null,
  },
  consoleAccessGiven: false,
  requestRestart: false,
  loading: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case at.ACCESS_SHARED_CONSOLE:
      return {
        ...state,
        details: {
          ...state.details,
          password: payload.password
        },
        loading: true,
      };
    case at.ACCESS_SHARED_CONSOLE_SUCCESS:
      return {
        ...state,
        consoleAccessGiven: true,
        details: {
          ...payload,
          password: state.details.password
        },
        loading: false,
      };
    case at.ACCESS_SHARED_CONSOLE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case at.SEND_REQUEST_RESTART_SUCCESS:
      return {
        ...state,
        loading: false,
        requestRestart: true
      };
    case at.SEND_REQUEST_RESTART_ERROR:
      return {
        ...state,
        loading: false,
        requestRestart: false
      };
    default:
      return state;
  }
};

export const isLoading = ({ sharedConsole }) => sharedConsole.loading;

export const getData = ({ sharedConsole }) => sharedConsole;

export const isRequestRestart = ({ sharedConsole }) => sharedConsole.requestRestart;
