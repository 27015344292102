import { actionTypes as at } from './actions';
import { AnyAction } from 'redux';

export const initialState = {
  connectionData: null,
  rdpData: null,
  rdpMessage: null,
  rdpSendMessage: null,
  socketState: "closed",
  websocket: null,
  socketId: null,
  loading: false,
  specialKey: null,
  shouldHaveEventListeners: true, 
};

export const reducer = (state = initialState, { type, payload, websocket }: AnyAction) => {
  switch (type) {
    case at.WS_RDP_CONNECT:
      return {
        ...state,
        loading: true,
        connectionData: payload,
      };
    case at.WS_RDP_CONNECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case at.WS_RDP_CONNECT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,        
      };
      case at.WS_RDP_OPEN:
        return {
          ...state,
          loading: false,
          socketState: payload.type,
          websocket: websocket,                   
        };
    case at.WS_RDP_CLOSE:
        return {
          ...state,
          loading: false,
          socketState: payload.type,
          websocket: null,
          socketId: null,
        };
    case at.WS_RDP_ERROR:
        return {
          ...state,
          loading: false,
          socketState: payload.type,
          websocket: null,
          socketId: null,
        };
    case at.WS_RDP_MESSAGE:
        switch (typeof(payload.data)) {
            case 'string':
                return {
                    ...state,
                    loading: false,
                    rdpMessage: payload,
                  };
                break;
            case 'object':
                //skip this because these messages will be processed 
                break;
        }        
        return {
          ...state,
          loading: false,
        };
    case at.WS_SET_SID:
        return {
          ...state,
          loading: false,
          socketId: payload,
        };
    case at.WS_WRITE_DEBUG_CONSOLE:
        return {
          ...state,
        };
    case at.WS_RDP_PROCESS_DATA:
        //console.log(payload)
        return {
            ...state,
            rdpData:payload
        }
    case at.WS_RDP_SEND_MESSAGE:
        return {
            ...state,
            rdpSendMessage:payload.message
        }
    case at.WS_RDP_SEND_SPECIAL_KEY:
        return {
          ...state,
          specialKey: payload.keyCombination,
        }
    case at.WS_RDP_DISCONNECT:
        return {
            ...state, 
            socketState: "closed",
            websocket: null,
            socketId: null,  
        }
    case at.WS_ADD_EVENT_LISTENERS:
      return {
        ...state, 
        shouldHaveEventListeners: true,
    }
    case at.WS_REMOVE_EVENT_LISTENERS:
      return {
        ...state, 
        shouldHaveEventListeners: false,
    }
    default:
      return state;
  }
};

const baseSelector = ({ vmConsoleSocketConnection }) => {
    return vmConsoleSocketConnection;
  }
export const getRDPData = (state) => baseSelector(state).rdpData;

export const isLoading = (state) => baseSelector(state).loading;

export const getSendRDPData = (state) => baseSelector(state).rdpSendMessage;

export const getSpecialKeyCombination = ( state ) => baseSelector(state).specialKey;

export const getConsoleConnectionData = ( state ) => baseSelector(state).connectionData;

export const getWebSocket = ( state ) => baseSelector(state).websocket;

export const getWebSocketState = ( state ) => baseSelector(state).socketState;

export const shouldHaveEventListeners = ( state ) => baseSelector(state).shouldHaveEventListeners;