import { ActionType } from 'typesafe-actions';
import { actionTypes as at, actionCreators } from "./actions";
import Types from 'Types';


export type Action = ActionType<typeof actionCreators>;

export const initialState = {
  data: [],
  price: '',
  loading: true,
};

const reducer = (state = initialState,  { type, payload }: Action & Types.StoreAction) => {
  switch (type) {
      case at.LOAD_SETTINGS:
        return {
          ...state,
          loading: true,
        };
      case at.LOAD_SETTINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: payload,
        }
      case at.CALCULATE_PRICE_SUCCESS:
        return {
          ...state,
          price: payload.fullyFormatted,
        }    
      default:
        return state;
  }
};

export default reducer;

export const isLoading = ({ cloudCalculator }: Types.StoreState) => cloudCalculator.loading;

export const getData = ({ cloudCalculator }: Types.StoreState) => cloudCalculator.data;

export const getPrice = ({ cloudCalculator }: Types.StoreState) => cloudCalculator.price;

