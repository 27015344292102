import * as React from 'react';
import { connect } from 'react-redux';
import Button from '../../../shared/components/Button';
import { isLoading, getData } from '../reducer';
import { actionCreators as ac } from '../actions';
import { showModal } from '../../../shared/sagas/modals/actions';
import { translate as t } from '../../../l10n';
import { History } from "history";

export interface InactiveAccountProps {
  match: {
    params: {
      email: string;
    },
    url: string;
  };
  history: History;
  showModal: (meta) => void;
  sendActivation: (arg: {
    email: string;
  }) => void
}


class InactiveAccount extends React.Component<InactiveAccountProps> {
  private email: string | null;
  history: History;
  constructor(props: InactiveAccountProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data) {
      let msgPopup = "";
      const { status } = data;
      if (status === "error") {
        msgPopup = t('guest', "inactiveAccount.errorMessage");
      }
      else {
        msgPopup = t('guest', "inactiveAccount.successMessage")
      }
      this.props.showModal({
        type: 'alert',
        alertType: (status === 'error') ? 'error' : 'success',
        message: msgPopup,
        onClose: () => {
          this.props.history.push("/");
        },
      });
    }
  }

  componentDidMount() {
    const emailParam = this.props.match.params.email;
    if (emailParam) {
      this.email = emailParam.replace('email=', '');
    }
    const match = this.props.match.url.match(/^\/([^/]*)\//);
    if (match) {
      window.location.hash = `/${match[1]}`;
    }
  }

  handleClick() {
    this.props.sendActivation({ email: this.email });
  }

  render() {
    return (<div className="form-page">
      <div className="mesage-info">
        <p>{t('guest', 'inactiveAccount.message')}</p>
      </div>
      <Button
        rounded
        gradient="light"
        onClick={this.handleClick}
        className="mt-30"
      >
        {t('common', 'button.send')}
      </Button>
    </div>);
  }
}

const mapStateToProps = state => ({
  loading: isLoading(state),
  data: getData(state),
});

const mapDispatchToProps = {
  sendActivation: ac.sendActivation,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InactiveAccount);

