import * as React from 'react';
import classNames from 'classnames';
import './styles/Switch.scss';
import { translate as t } from '../../l10n';

export interface SwitchProps {
  onChange: (...args: any[]) => void;
  labels?: React.ReactNode[];
  checked?: boolean;
  disabled?: boolean;
  className?: string;
};

class Switch extends React.Component<SwitchProps> {
  static defaultProps = {
    labels: [t('common', 'switch.on'), t('common', 'switch.off')],
    checked: false,
    disabled: false,
  };
  constructor(props) {
    super(props);
    this.toggleClick = this.toggleClick.bind(this);
  }

  toggleClick(e) {
    this.props.onChange(e.target.checked, e);
  }

  render() {
    const {
      labels, checked, disabled, onChange, className, ...rest
    } = this.props;
    const labelClassName = classNames('switch', className, {
      'switch--disabled': disabled,
    });
    return (
      <label className={labelClassName} {...rest}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={this.toggleClick}
        />
        <span className="slider round" />
        <span className="switch-label">{checked ? labels[0] : labels[1]}</span>
      </label>
    );
  }
}

export default Switch;
