import * as React from 'react';
import App from './App';
import rootReducer from './reducers';
import { configureStore } from './configureStore';
import sagas from './sagas';

const { store, history } = configureStore(rootReducer, sagas);


if (process.env.NODE_ENV !== 'production' && module.hot) {
    if (module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer as any));
    }
}


export default () => <App store={store} history={history} />;
