import { translate } from '../../../../l10n';

const t = (str, context = 'common') => translate(context, str);

/* eslint-disable no-useless-escape */
const validationRules = {
  name: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.firstName')),
    },
    length: {
      value: [2, 40],
      message: t('form.lengthTemplate')(t('form.firstName'), 2, 40),
    },
    pattern: {
      value: /[^\~!@#$%\^&\*\+\=\-\(\)_\+\|><\?\/,\.\t\n\r\{\}\[\]\\\'"№§:;\d€]+/,
      message: t('form.nameTemplate')(t('form.firstName')),
    },
  },
  firstNameAndLastName: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.firstNameAndLastName')),
    },
    length: {
      value: [2, 40],
      message: t('form.lengthTemplate')(t('form.firstNameAndLastName'), 2, 40),
    },
    pattern: {
      value: /[^\~!@#$%\^&\*\+\=\-\(\)_\+\|><\?\/,\.\t\n\r\{\}\[\]\\\'"№§:;\d€]+/,
      message: t('form.nameTemplate')(t('form.firstNameAndLastName')),
    },
  },
  Name: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.firstNameAndLastName')),
    },
    length: {
      value: [2, 50],
      message: t('form.lengthTemplate')(t('form.firstNameAndLastName'), 2, 50),
    },
    pattern: {
      ///^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
      //value: /[^\~!@#$%\^&\*\+\=\-\(\)_\+\|><\?\/,\.\t\n\r\{\}\[\]\\\'"№§:;\d€]+/,
      value: /[^\~!@#$%\^&\*\+\=\(\)_\+\|><\?\/,\t\n\r\{\}\[\]\\\"№§:;\d€]+/,
      message: t('form.nameTemplate')(t('form.firstNameAndLastName')),
    },
  },
  firstName: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.firstName')),
    },
    length: {
      value: [2, 45],
      message: t('form.lengthTemplate')(t('form.firstName'), 2, 45),
    },
    pattern: {
      value: /(?!^.*[<>%;#}{\(\)^~!,.@$?+=*\\\/"№§:;\d€|].*$)(?!^.*-'-.*$)(?!^.*'-'.*$)(?!^.* {2}.*$)(?!^.*--.*$)(?!^.*''.*$)^.*$/,
      message: t('form.nameTemplate')(t('form.firstName')),
    },
  },
  eikNumber: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.eikNumber')),
    },
    length: {
      value: [9, 13],
      message: t('form.lengthTemplate')(t('form.eikNumber'), 9, 13),
    },
    pattern: {
      value: /^[\d]+$/,
      message: t('form.numericTemplate')(t('form.eikNumber')),
    },
  },
  vatNumber: {
    // isRequired: {
    //   message: t('form.requiredFieldTemplate')(t('form.vatNumber')),
    // },
    length: {
      value: [0, 30],
      message: t('form.lengthTemplate')(t('form.vatNumber'), 0, 30),
    },
    pattern: {
      value: /[^\~!@$%\^&\*_\+\|\>\<\?\t\n\r\{\}\[\]\\\§€]+/,
      message: t('form.forbiddenCharactersTemplate')(t('form.vatNumber')),
    },
  },
  companyName: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.companyName')),
    },
    length: {
      value: [2, 100],
      message: t('form.lengthTemplate')(t('form.companyName'), 2, 100),
    },
    pattern: {
      value: /[^\~!@$%\^&\*_\+\|\>\<\?\t\n\r\{\}\[\]\\\§€]+/,
      message: t('form.forbiddenCharactersTemplate')(t('form.companyName')),
    },
  },
  lastName: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.lastName')),
    },
    length: {
      value: [2, 45],
      message: t('form.lengthTemplate')(t('form.lastName'), 2, 45),
    },
    pattern: {
      value: /(?!^.*[<>%;#}{\(\)^~!,.@$?+=*\\\/"§:;\d€|].*$)(?!^.*-'-.*$)(?!^.*'-'.*$)(?!^.* {2}.*$)(?!^.*--.*$)(?!^.*''.*$)^.*$/,
      message: t('form.nameTemplate')(t('form.lastName')),
    },
  },
  city: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.city')),
    },
    length: {
      value: [2, 45],
      message: t('form.lengthTemplate')(t('form.city'), 2, 45),
    },
    pattern: {
      value: /(?!^.*[<>%;#}{\(\)^~!,.@$?+=*\\\/"§:;\d€|].*$)(?!^.*-'-.*$)(?!^.*'-'.*$)(?!^.* {2}.*$)(?!^.*--.*$)(?!^.*''.*$)^.*$/,
      message: t('form.nameTemplate')(t('form.city')),
    },
  },
  address: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.address')),
    },
    length: {
      value: [3, 100],
      message: t('form.lengthTemplate')(t('form.address'), 3, 100),
    },
  },
  addressDetails: {
    // isRequired: {
    //   message: t('form.requiredFieldTemplate')(t('form.addressDetails')),
    // },
    length: {
      value: [3, 100],
      message: t('form.lengthTemplate')(t('form.addressDetails'), 3, 100),
    },
  },
  state: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.state')),
    },
  },
  zipCode: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.zipCode')),
    },
    pattern: {
      value: /^[\d]+$/,
      message: t('form.invalidZipeCode'),
    },
  },
  email: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.email')),
    },
    pattern: {
      ///^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      //value: /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm,
      //value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //value: /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
      message: t('form.wrongEmail'),
    },
  },
  emailMarketing: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.email')),
    },
    pattern: {
      ///^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      //value: /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]{1,})?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm,
      //value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      //value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //value: /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
      value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: t('form.wrongEmailMarketing'),
    },
  },
  password: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.password')),
    },
    pattern: {
      value: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[~!@#\^\$&\*\(\)_\+=\[\]\{\}\|,\.\?\s<>'\":;`%|-]){1,})(?!.*\s)[a-zA-Z0-9~!@#\^\$&\*\(\)_\+=\[\]\{\}\|,\.\?\s<>'\":;`%|-]{8,20}$/,
      message: t('form.invalidPassword'),
    },
  },
  confirmPassword: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.confirmPassword')),
    },
  },
  PhoneNumber: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.phone')),
    },
    // length: {
    //   value: [8, 17],
    //   message: t('form.lengthTemplate')(t('form.phone'), 8, 17),
    // },
    phoneLength: {
      value: [8, 12],
      message: t('form.lengthTemplate')(t('form.phone'), 8, 12),
    },
    phonePattern: {
      //08+[\d]{8}$|^3598+[\d]{8}$|^0+([^8]{1})+[\d]{6,10}$|^359+([^8]{1})+[\d]{6,10}$|[^3598|08]+[\d]{8,12}
      //value: /([\+ 0-9\-])*/,
      value: /(08+[\d]{8}$|^3598+[\d]{8}$|^0+([^8]{1})+[\d]{6,10}$|^359+([^8]{1})+[\d]{6,10}$|[^3598|08]+[\d]{8,12})*/,
      //value: /(\+)?(359|0)8[789]\d{1}(|-| )\d{3}(|-| )\d{3}/,
      message: t('form.invalidPhoneMarketing'),
    },
  },
  phone: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.phone')),
    },
    length: {
      value: [3, 30],
      message: t('form.lengthTemplate')(t('form.phone'), 3, 30),
    },
    pattern: {
      value: /([\+ 0-9\-])*/,
      message: t('form.invalidPhone'),
    },
  },
  title: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.title')),
    },
    length: {
      value: [3, 200],
      message: t('form.lengthTemplate')(t('form.title'), 3, 200),
    },
  },
  message: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.text')),
    },
    length: {
      value: [6, 2000],
      message: t('form.lengthTemplate')(t('form.text'), 6, 2000),
    },
  },
  captcha: {
    isRequired: {
      message: t('form.requiredCaptcha'),
    },
  },
  label: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.label')),
    },
    // pattern: {
    //   //^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$
    //   value: /^[a-zA-Z0-9!@#$&()`.+,\/"\-\s]*$/,
    //   message: t('form.invalidField')(t('form.label')),
    // },
  },
  acceptInstructions: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.acceptInstructions')),
    },
  },
  accessCode: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.accessCode')),
    },
    length: {
      value: [3, 20],
      message: t('form.lengthTemplate')(t('form.accessCode'), 3, 20),
    },
  },
  InternalPort: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.port')),
    },
    length: {
      value: [1, 5],
      message: t('form.lengthTemplate')(t('form.port'), 1, 5),
    },
    pattern: {
      value: /^([1-9][0-9]{0,5})/,
      message: t('form.invalidPorts'),
    },
  },
  ExternalPort: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.port')),
    },
    length: {
      value: [1, 5],
      message: t('form.lengthTemplate')(t('form.port'), 1, 5),
    },
    pattern: {
      value: /^([1-9][0-9]{0,5})/,
      message: t('form.invalidPorts'),
    },
  },
  ProtocolName: {
    isRequired: {
      message: t('form.selectProtocolValidation'),
    },
    pattern: {
      value: /(^[0-9]*[1-9][0-9]*$)*/,
      //^[0-9]*[1-9][0-9]*$
      message: t('form.selectProtocolValidation'),
    },
  },
  vmID: {
    isRequired: {
      message: t('form.selectVmValidation'),
    },
    vmIDPattern: {
      value: /(o-[0-9]{1,})/,
      message: t('form.selectVmValidation'),
    }
  },
  Algorithm: {
    isRequired: {
      message: t('form.selectVmValidation'),
    },
    vmIDPattern: {
      value: /(^[0-9]*[1-9][0-9]*$)*/,
      message: t('form.selectVmValidation'),
    }
  },
  Persistance: {
    isRequired: {
      message: t('form.selectVmValidation'),
    },
    vmIDPattern: {
      value: /(^[0-9]*[1-9][0-9]*$)*/,
      message: t('form.selectVmValidation'),
    }
  },
  requestForDeactivation: {
    isRequired: {
      message: t('account.deactivationRequestIsRequire'),
    },
    leadsLength: {
      value: [50, 350],
      message: t('form.lengthTemplate')(t('account.deactivationRequestTextPopUp'), 50, 350),
    },
  },
  firstNameLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.firstName')),
    },
    length: {
      value: [2, 40],
      message: t('form.lengthTemplate')(t('form.firstName'), 2, 40),
    }
  },
  lastNameLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.lastName')),
    },
    length: {
      value: [2, 80],
      message: t('form.lengthTemplate')(t('form.lastName'), 2, 80),
    }
  },
  phoneLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.phone')),
    },
    length: {
      value: [2, 40],
      message: t('form.lengthTemplate')(t('form.phone'), 1, 40),
    }
  },
  emailLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.email')),
    },
    length: {
      value: [5, 80],
      message: t('form.lengthTemplate')(t('form.email'), 5, 80),
    }
  },
  titleLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.title2')),
    },
    length: {
      value: [1, 40],
      message: t('form.lengthTemplate')(t('form.title2'), 1, 40),
    }
  },
  companyLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.companyName')),
    },
    length: {
      value: [1, 40],
      message: t('form.lengthTemplate')(t('form.companyName'), 1, 40),
    }
  },
  cityLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.city')),
    },
    length: {
      value: [1, 20],
      message: t('form.lengthTemplate')(t('form.city'), 1, 20),
    }
  },
  streetLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.address')),
    }
  },
  descriptionLeads: {
    isRequired: {
      message: t('form.requiredFieldTemplate')(t('form.description')),
    },
    leadsLength: {
      value: [10, 1000],
      message: t('form.lengthTemplate')(t('form.description'), 10, 1000),
    },

  },
  industryLeads: {
    isRequired: {
      message: t('form.chooseIndustry'),
    },
    pattern: {
      value: /^[A-Za-z]+$/,
      //^[0-9]*[1-9][0-9]*$
      message: t('form.chooseIndustry'),
    },
  },
};

export function addRules(rules) {
  const names = Object.keys(rules);
  names.forEach((name) => {
    if (!validationRules[name]) {
      validationRules[name] = rules[name];
    } else {
      console.error(`Validation rule with name "${name}" already exists.`);
    }
  });
}

export const email = validationRules.email;

export default validationRules;
