import keyMirror from 'key-mirror';
import { createStandardAction } from "typesafe-actions";
import { getBrowser } from '../../../scripts/common/detect';
import { userEmail } from '../../../scripts/common/db';

export const actionTypes = keyMirror({
  DOCUMENT_READY: null,

  SHOW_COOKIE_CONSENT: null,

  SHOW_PERSONAL_DATA_POPUP: null,

  SEND_ERROR_REPORT: null,
  SEND_ERROR_REPORT_ERROR: null,
  SEND_ERROR_REPORT_SUCCESS: null,
});

const at = actionTypes;


export const { DOCUMENT_READY } = actionTypes;

function saveErrorReport(error, componentStack) {
  return {
    error: {
      name: error.name,
      message: error.message,
      stack: error.stack,
    },
    componentStack,
    browser: getBrowser(),
    email: userEmail || 'GUEST',
  }
}

export const actionCreators = {
  documentReady: createStandardAction(at.DOCUMENT_READY)<void>(),
  showCookieConsent: createStandardAction(at.SHOW_COOKIE_CONSENT)<void>(),
  showPersonalDataPopup: createStandardAction(at.SHOW_PERSONAL_DATA_POPUP)<void>(),
  sendErrorReport: createStandardAction(at.SEND_ERROR_REPORT).map(
    (error, componentStack) => ({
      meta: {
        request: {
          url: '/api/tools/jslog',
          method: 'POST',
          body: JSON.stringify(saveErrorReport(error, componentStack)),
          headers: {
            contentType: "application/json; charset=utf-8",
          }
        },
        suppressError: true,
      }
    })
  ),
}
