const dataset = document.head.dataset;
export const userId = dataset.user;
export const accountId = dataset.userId;
export const userDisplayname = dataset.userDisplayname;
export const requestToken = (document.querySelector('input[name=__RequestVerificationToken]') as HTMLInputElement).value;
export const userEmail = dataset.userEmail;
export const isGuestUser = userDisplayname === userEmail;


let tracker: object;
let callbacks = [];


function checkIfAnalyticsLoaded() {
  if (window.ga && window.ga.create) {
    tracker = window.ga.getAll()[0];
    callbacks.forEach((cb) => {
      cb(tracker);
    });
    callbacks = [];
  } else {
    setTimeout(() => {
      checkIfAnalyticsLoaded();
    }, 500);
  }
}

export function analyticsTracker(callback: (p?: any) => void) {
  if (tracker) {
    callback(tracker);
  } else {
    callbacks.push(callback);
    checkIfAnalyticsLoaded();
  }
}


