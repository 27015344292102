import * as React from 'react';
import { connect } from "react-redux";
import './styles/ErrorBoundary.scss';
import Button from './Button';
import { actionCreators as ac } from "../../shared/sagas/common/actions";
import { getData } from "../../shared/sagas/common/reducer";
import CookieConsent from './CookieConsent';
import GDPRpopup from './GDPRpopup';
import { showModal } from "../../shared/sagas/modals/actions";
import { translate } from '../../l10n';

const t = (str: string, context = 'common') => translate(context, str);


export interface ErrorBoundaryProps {
  children: React.ReactNode;
  layout?: React.ReactNode,
  location?: {
    pathname: string,
  },
  onDocumentReady: () => void;
  sendErrorReport: (error: any, info: any) => void;
  showModal: (meta) => void;
  data: {
    showCookieConsent: boolean,
    showPersonalDataPopup: boolean,
  }
};

interface State {
  readonly hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.onDocumentReady();
  }

  componentDidMount() {
    // show owncloud error message in modal window (if any)
    const errorContainer = document.querySelector('.error-wide');
    if (errorContainer) {
      const html = `<ul>${errorContainer.innerHTML}</ul>`;
      setTimeout(() => {
        const dialogMessage = document.querySelector('.dialog__message');
        dialogMessage.innerHTML = html;
        const pre = document.querySelector('.dialog__message pre') as HTMLElement;
        pre.style.display = "none";
      }, 300);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ErrorBoundaryProps) {
    if (this.state.hasError && (nextProps.location.pathname !== this.props.location.pathname)) {
      this.setState({ hasError: false });
    }

    if (nextProps.data.showPersonalDataPopup && !document.cookie.includes("cookie_consent_bit")) {
      setTimeout(() => {
        this.props.showModal({
          type: 'custom',
          content: props => <GDPRpopup {...props} />,
        });
      }, 700);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log('sendErrorReport', { error, info });
    this.props.sendErrorReport(error, info);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      //const Layout = this.props.layout;
      return (<React.Fragment
        {...this.props}
      >
        <div className="error-boundary">
          <h1>{t('errorBoundary.somethingWentWrong')}</h1>
          <Button type="black" onClick={() => { window.history.back(); }}>{t('button.goBack')}</Button>
          <Button type="primary" onClick={() => { window.location.reload(); }}>{t('button.refresh')}</Button>
        </div>
      </React.Fragment>);
    }
    return (<React.Fragment>
      {
        !!this.props.data.showCookieConsent && <CookieConsent {...this.props} />
      }
      {this.props.children}
    </React.Fragment>);
  }
}

const mapStateToProps = state => ({
  data: getData(state),
});

const mapDispatchToProps = {
  onDocumentReady: ac.documentReady,
  sendErrorReport: ac.sendErrorReport,
  showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
