import * as React from 'react';
import classNames from 'classnames';
import './styles/DropdownMenu.scss';

export interface DropdownMenuProps {
  //component: (...args: any[]) => JSX.Element | any,
  component: any,
  containerHeight: number;
  contentClassName?: string,
  componentProps?: object;
};

export interface DropdownMenuState {
  isActive: boolean;
}

class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
  private timeStamp: number | null;
  constructor(props: DropdownMenuProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.setTimeStamp = this.setTimeStamp.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      isActive: false,
    };
    this.timeStamp = null;
  }

  toggleDropdown() {
    if (!this.state.isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  handleClick(event) {
    if (event.timeStamp !== this.timeStamp) {
      this.hide();
    }
  }

  show() {
    this.setState({ isActive: true });
    document.addEventListener('click', this.handleClick);
  }

  hide() {
    this.setState({ isActive: false });
    document.removeEventListener('click', this.handleClick);
  }

  setTimeStamp(event) {
    event.stopPropagation();
    this.timeStamp = event.timeStamp;
  }

  render() {
    if (/login|register-guest/.test(window.location.hash)) {
      return null;
    }
    const dropdownClass = classNames('dropdown', {
      'dropdown--active': this.state.isActive,
    });
    const dropdownStyle = {
      height: this.state.isActive ? `${this.props.containerHeight}px` : '0',
    };
    const { component: ComponentContent, contentClassName, componentProps } = this.props;
    return (<div
      className={dropdownClass}
      onClick={this.toggleDropdown}
    >
      <span className="dropdown__menu">
        <span className="dropdown__bar dropdown__bar--first" />
        <span className="dropdown__bar dropdown__bar--middle" />
        <span className="dropdown__bar dropdown__bar--last" />
      </span>
      <div
        className="dropdown__content"
        style={dropdownStyle}
        onClick={this.setTimeStamp}
      >
        <div className={contentClassName}>
          <ComponentContent hideDropdown={this.hide} {...componentProps} />
        </div>
      </div>
    </div>
    );
  }
}

export default DropdownMenu;
