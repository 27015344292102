const loaded: string[] = [];

function importScript(attributes) {
  const { src } = attributes;
  if (loaded.includes(src)) {
    return new Promise((resolve) => {
      resolve();
    });
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  for (const key in attributes) {
    script.setAttribute(key, attributes[key]);
  }

  return new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      loaded.push(src);
      resolve();
    });
    script.addEventListener('error', (error) => {
      console.warn(`Error loadind script "${src}".`, error);
      reject(error);
    });
    document.body.appendChild(script);
  });
}

export default importScript;
