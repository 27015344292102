import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";

interface LocationProps {
    hash: string;
    pathname: string;
}

type childrenProps = React.ReactNode;

class ScrollToTop extends React.Component<RouteComponentProps<LocationProps & childrenProps>> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);

