import * as React from "react";
import { createPortal } from "react-dom";

export interface ModalPortalProps {
  children: React.ReactNode;
}

class ModalPortal extends React.PureComponent<ModalPortalProps> {
  render() {
    return createPortal(this.props.children, document.body);
  }
}

export default ModalPortal;
