import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

function renderTrackVertical({ style, ...props }) {
  const finalStyle = {
    ...style,
    right: 2,
    bottom: 2,
    top: 1,
    width: '8px',
  };
  return <div style={finalStyle} {...props} />;
}

function renderThumbVertical({ style, ...props }) {
  const finalStyle = {
    ...style,
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
  };
  return <div style={finalStyle} {...props} />;
}

function renderTrackHorizontal({ style, ...props }) {
  const finalStyle = {
    ...style,
    right: 2,
    bottom: 2,
    left: 2,
    height: '8px',
  };
  return <div style={finalStyle} {...props} />;
}

export function renderThumbHorizontal({ style, ...props }) {
  const finalStyle = {
    ...style,
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0,0,0,.2)',
  };
  return <div style={finalStyle} {...props} />;
}

renderTrackVertical.propTypes = {
  style: PropTypes.shape({}).isRequired,
};

renderThumbVertical.propTypes = {
  style: PropTypes.shape({}).isRequired,
};

renderTrackHorizontal.propTypes = {
  style: PropTypes.shape({}).isRequired,
};

renderThumbHorizontal.propTypes = {
  style: PropTypes.shape({}).isRequired,
};

const Scrollbar = props => (<Scrollbars
  renderThumbVertical={renderThumbVertical}
  renderTrackVertical={renderTrackVertical}
  renderThumbHorizontal={renderThumbHorizontal}
  renderTrackHorizontal={renderTrackHorizontal}
  autoHide
  {...props}
/>);

export default Scrollbar;
