import _fetch from "isomorphic-fetch";
import host from "./host";
import { requestToken } from "../../../../scripts/common/db";

export type Request = {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: string | FormData | Blob;
  headers?: {
    [x: string]: string;
  };
  mode?: "json" | "blob" | "arrayBuffer" | "text" | "withToken";
  withToken?: boolean;
};

export type HeadersProps = Pick<Request, "mode" | "headers" | "withToken">;

export const parseResponse = (mode) => (response) => {
  if (response.status === 204) {
    return null;
  }
  if (response.status >= 200 && response.status < 300) {
    if (mode === "withToken") {
      return response["json"]();
    }
    return response[mode]();
  }

  throw {
    response,
    error: response.status,
  };
};

const defaultHeaders = {
  "X-Requested-With": "XMLHttpRequest",
};

const setHeaders = ({ mode, headers, withToken }: HeadersProps) => {
  const requestHeaders = {
    ...defaultHeaders,
  };
  console.log(document.head.dataset);
  console.log(document.cookie);
  if (mode === "json") {
    requestHeaders["Accept"] = "application/json";
    requestHeaders["Content-Type"] = "application/json; charset=UTF-8";
    requestHeaders["__RequestVerificationToken"] =
    requestToken || document.head.dataset.requesttoken;
    requestHeaders['UserEmail'] = document.head.dataset.userEmail;
  }

  if (withToken) {
    requestHeaders["__RequestVerificationToken"] =
      requestToken || document.head.dataset.requesttoken;
  }
  console.log(requestHeaders);
  return {
    ...requestHeaders,
    ...headers,
  };
};

export const appFetch = ({
  url,
  mode = "json",
  headers,
  withToken,
  ...rest
}: Request) => {
  return _fetch(`${host}${url}`, {
    ...rest,
    headers: setHeaders({ mode, headers, withToken }),
  })
    .then(parseResponse(mode))
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
