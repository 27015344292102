import { actionTypes as at } from "./actions";
import Types from 'Types';
import { AnyAction } from 'redux';


export const initialState = {
  data: null,
  loading: false,
  resetData: null,
};

export const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case at.EMAIL_CONFIRMATION:
      return {
        ...state,
        loading: true,
      }
    case at.EMAIL_CONFIRMATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case at.EMAIL_CONFIRMATION_SUCCESS:
      //console.log(payload)
      return {
        ...state,
        loading: false,
        data: payload,
      }
    case at.EMAIL_CONFIRMATION_RESET_CODE:
      return {
        ...state,
        loading: true,
      }
    case at.EMAIL_CONFIRMATION_RESET_CODE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      }
      case at.EMAIL_CONFIRMATION_RESET_CODE_SUCCESS:
        //console.log(payload)
        return {
          ...state,
          loading: false,
          resetData: payload,
        }
    default:
      return state;
  }
};

export default reducer;

export const getData = ({ confirmRegistration }: Types.StoreState) => confirmRegistration.data;

export const getResetCodeData = ({ confirmRegistration }: Types.StoreState) => confirmRegistration.resetData;

export const isLoading = ({ confirmRegistration }: Types.StoreState) => confirmRegistration.loading;
