import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles/HeaderLinks.scss';
import { translate } from '../../l10n';

export interface HeaderLinksProps {
    // notifications?: () => JSX.Element
    notifications?: any
}



export class HeaderLinks extends React.PureComponent<HeaderLinksProps> {

    render() {
        const { notifications: Notifications } = this.props;
        return (
            <div className="header__links">
                <a className="header__link header__link--youtube" href="https://www.youtube.com/user/oblakbg/featured" target="_blank" rel="noopener noreferrer">
                    <i className="header__youtube-link icon-external-link" />
                    <i className="header__icon icon-youtube" />
                    <span>YouTube</span>
                </a>
                <Link to="/contact-us" className="header__link header__link--contact-us">
                    <i className="header__icon icon-contact-us" />
                    <span>{translate('common', 'contactUs')}</span>
                </Link>
                {!!Notifications && <Notifications />}
                <div className="header__link header__link--phone">
                    <i className="icon-phone" />
                    <span className="header__link--phone-text">{translate('common', 'phoneNumber')}</span>
                </div>
            </div>
        );
    }
}


export default HeaderLinks;
