import { combineReducers } from 'redux';
import { reducer as contactUs } from './contactUs';
import { reducer as resetPassword } from './resetPassword';
import { reducer as checkResetPasswordCode } from './checkResetPasswordCode';
import { reducer as forgottenPassword } from './forgottenPassword';
import { reducer as registration } from './registration';
import { reducer as login } from './login';
import { reducer as emailMarketing } from './emailMarketing'

export default combineReducers({
    registration,
    resetPassword,
    checkResetPasswordCode,
    contactUs,
    forgottenPassword,
    login,
    emailMarketing,
});
