import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../../shared/pages/styles/formPage.scss";
import "./styles/RegistrationForm.scss";
import Form, { IForm } from "../../../shared/components/Form";
import {
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  captcha,
} from "../../../shared/components/Form/js/fields";
import importScript from "../../../scripts/common/importScript";
import { doesNotContainString, checkStringNotInAnotherString } from "../../../shared/components/Form/js/passwordHints";
import { isLoading, getData } from "../../submit-form/reducer/registration";
import { actionCreators as ac } from "../../submit-form/actions";
import { showModal } from "../../../shared/sagas/modals/actions";
import { AlertModal } from "../../../shared/sagas/modals/types";
import { translate } from "../../../l10n";
import { History } from "history";
import { requestToken } from "../../../scripts/common/db";

const t = (str, context = "common") => translate(context, str);
const tt = (str, context = "guest") => translate(context, str);

const fields = {
  firstName,
  lastName,
  email,
  password: {
    ...password,
    customRules: [
      {
        fields: ["firstName", "lastName"],
        validate: doesNotContainString,
        message: tt("registrationForm.passwordContainName"),
      },
      {
        fields: ['email'],
        validate: checkStringNotInAnotherString,
        message: tt("registrationForm.passwordContainEmail"),
      }
    ],
  },
  confirmPassword: {
    ...confirmPassword,
    customRules: [
      {
        fields: ["password"],
        validate(value, pas) {
          return !(pas && pas === value);
        },
        message: t("form.passwordsDoNotMatch"),
      },
    ],
  },
  captcha,
  AcceptTermsAndConditions: {
    name: "AcceptTermsAndConditions",
    type: "checkbox",
    label: (
      <p className="registration__terms-text">
        {t("form.termsAndConditions")}
        <Link className="anim-underline" to="/terms">
          {t("footer.terms")}
        </Link>
        ,
        <Link className="anim-underline" to="/privacy">
          {t("footer.privacy")}
        </Link>{" "}
        {t("form.and")}
        <Link className="anim-underline" to="/service-level">
          {t("footer.serviceLevel")}
        </Link>{" "}
        *
      </p>
    ),
    message: t("form.requiredTermsAndConditions"),
    checkboxHuge: true,
  },
};

export interface RegistrationFormProps {
  loading: boolean;
  responseData: {
    status: "error" | "ok";
    invalidFields: string[];
    messages: string[];
    error: any;
    data: any;
  };
  error: object;
  onSubmitHandler: (data) => void;
  showModal: (meta: AlertModal) => void;
  history: History;
}

class RegistrationForm extends React.Component<RegistrationFormProps> {
  private form: IForm;
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccessfulRegistration = this.onSuccessfulRegistration.bind(this);
  }

  componentDidMount() {
    importScript({
      src:
        "https://www.google.com/recaptcha/api.js?hl=bg&onload=onloadCallback&render=explicit",
      async: true,
      defer: true,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: RegistrationFormProps) {
    const { responseData: data, loading } = nextProps;
    if (data) {
      if (data.status === "error") {
        const { error } = data;
        //if (invalidFields.length) {
        //this.form.showServerValidationErrors(invalidFields.filter(name => name === 'g-recaptcha-response'), messages);
        //} else {
        this.props.showModal({
          type: "alert",
          title: "Нова Регистрация",
          alertType: "warning",
          message: error,
        });
        //}
      }
      if (data.status === "ok") {
        //const message = data.data;
        this.props.showModal({
          type: "alert",
          alertType: "success",
          title: "Нова Регистрация",
          message: t("form.registrationComplete"),
          onClose: this.onSuccessfulRegistration,
        });
        this.form.reset();
      }
    }

    if (!loading) {
      this.form.setState({ inProgress: false });
    }
  }

  onSuccessfulRegistration() {
    //console.log("success");
    const { history } = this.props;
    history.push("/");
  }

  onSubmit(data) {
    data["g-recaptcha-response"] = data.captcha;
    data.__RequestVerificationToken = requestToken;
    this.props.onSubmitHandler(data);
    // const promise = this.props.subscribeForPromise(request(body));

    // promise
    //   .then((res) => {
    //     // backend compatibility issue mtelPhone with phone
    //     if (res.data && res.data.data.invalidFields) {
    //       res.data.data.invalidFields = res.data.data.invalidFields.map((invalidField) => {
    //         if (invalidField === 'phone') {
    //           return 'mtelPhone';
    //         }
    //         return invalidField;
    //       });
    //     }
    //     this.props.onData.call(this, res);
    //   })
    //   .catch(this.props.onError.bind(this));
  }

  render() {
    return (
      <div className="registration">
        <Form
          fields={fields}
          buttonLabel={t("button.registration")}
          onSubmitForm={this.onSubmit}
          ref={(instance) => {
            this.form = instance;
          }}
        ></Form>
      </div>
    );
  }
}
/*
<div className="registration__offer-text">
          <p>Само за 32 стотинки на час с ДДС.</p>
        </div>
*/

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  responseData: getData(state),
});

const mapDispatchToProps = {
  onSubmitHandler: ac.registration,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
