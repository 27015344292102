import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import { Store } from 'redux';
import { History } from 'history';
const sagaMiddleware = createSagaMiddleware();

export const configureStore = (reducer, sagas, initialState?: object): {
  store: Store;
  history: History;
} => {
  const history = createHistory();
  const middlewares = applyMiddleware(
    sagaMiddleware,
    routerMiddleware(history),
  );

  const devtools =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f;

  const store = createStore(
    combineReducers({
      ...reducer,
      router: routerReducer,
    }),
    initialState,
    compose(middlewares, devtools),
  );

  /* let sagaTask = */ sagaMiddleware.run(sagas);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // module.hot.accept('./sagas', () => {
    //   const getNewSagas = require('./sagas');
    //   sagaTask.cancel();
    //   sagaTask.done.then(() => {
    //     sagaTask = sagaMiddleware.run(function* replacedSaga(action) {
    //       yield getNewSagas();
    //     } as any);
    //   });
    // });
    window.store = store;
  }

  return { store, history };
};
