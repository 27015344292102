import { call, put, select, takeLatest } from "redux-saga/effects";
import { LOCATION_CHANGE } from "react-router-redux";
import { actionTypes as at, actionCreators as ac } from "./actions";
import { isRequestRestart } from "./reducer";
import { AnyAction } from "redux";
import { showModal } from "../../sagas/modals/actions";
import { translate } from "../../../l10n";

const t = (str, context = "guest") => translate(context, str);

export function* handleAccessShareConsoleError() {
  yield put(
    showModal({
      type: "alert",
      alertType: "warning",
      message: t("sharedConsole.InvalidPassword"),
    })
  );
}

export function* handleSendRequestRestartSuccess() {
  yield put(
    showModal({
      type: "alert",
      alertType: "success",
      message: t("sharedConsole.SendRestartRequest"),
    })
  );
}

export function* handleRequestRestart({ payload }: AnyAction) {
  if (yield select(isRequestRestart)) {
    yield call(handleSendRequestRestartSuccess);
    return;
  }
  yield put(ac.requestRestartConsole(payload));
}

export function* handleSharedConsoleLoading() {
  yield takeLatest(at.REQUEST_RESTART, handleRequestRestart);
}

export const sagas = function* () {
  yield takeLatest(
    (action) =>
      action.type === LOCATION_CHANGE &&
      action.payload.pathname === "/shared-console",
    handleSharedConsoleLoading
  );
  yield takeLatest(
    at.SEND_REQUEST_RESTART_SUCCESS,
    handleSendRequestRestartSuccess
  );
  yield takeLatest(
    at.ACCESS_SHARED_CONSOLE_ERROR,
    handleAccessShareConsoleError
  );
};
