import * as React from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import * as cookies from "browser-cookies";
import "./styles/CookieConsent.scss";
import Button from "./Button";
import { translate } from "../../l10n";

const t = (str, context = "common") => translate(context, str);

interface State {
  cookieConsentAllow: boolean;
}

class CookieConsent extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      cookieConsentAllow: false,
    };
    this.onAccept = this.onAccept.bind(this);
  }

  onAccept() {
    const $banner = document.querySelector(".consent-banner");
    $banner.classList.remove("fade-in-bottom");
    $banner.classList.add("slide-out-bottom");
    setTimeout(() => {
      this.setState({
        cookieConsentAllow: true,
      });
      cookies.set("cookie_consent_given", "true", { expires: 365 });
    }, 200);
  }

  renderBanner() {
    if (this.state.cookieConsentAllow) {
      return null;
    }
    return (
      <div className="consent-banner fade-in-bottom">
        <span className="consent-message">
          {t("cookieConsent.oblakUseCookies")}
          <Link to="cookie-policy">{t("cookieConsent.policyCookies")}</Link>.
        </span>
        <div className="consent-buttons">
          <Button type="info" onClick={this.onAccept}>
            {t("button.understood", "common")}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return createPortal(this.renderBanner(), document.body);
  }
}

export default CookieConsent;
